<template>
  <div class="px-2 mt-1">
    <header-slot />
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: 'management-active-users-log' }"
        exact
        exact-active-class="active"
        :active="$route.meta.active_user === 1"
        :link-classes="['px-3', bgTabsNavs]"
      >
        ACTIVE
      </b-nav-item>

      <b-nav-item
        :to="{ name: 'management-inactive-users-log' }"
        exact
        exact-active-class="active"
        :active="$route.meta.active_user === 0"
        :link-classes="['px-3', bgTabsNavs]"
      >
        INACTIVE
      </b-nav-item>
    </b-nav>
    <b-card
      id="tab-users-log"
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {
  watch: {},
  mounted() {},
  methods: {},
};
</script>
